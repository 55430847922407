// include it in main scss file for table styles
.crudCard {
    .cardHead {
        input {
            background: rgba(196, 196, 196, 0.15);
            border-radius: 10px;
            width: 245px;
            padding: 6px 13px;
            border: none;
            &::placeholder {
                color: #0000006c;
                font-size: 10px;
            }
        }
    }
    .paginate {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 16px;
        margin-left: auto;
        .page {
            display: flex;
            padding: 0 14px;
            .current {
                font-size: 1.2em;
                letter-spacing: -1px;
                margin-right: -9px;
            }
            .divider {
                transform: rotate(51deg);
                font-size: 2.2em;
                font-weight: 100;
                color: #b5b5b5;
                align-self: center;
            }
            .total {
                font-size: 0.7em;
                align-self: flex-end;
                margin-left: -7px;
                margin-bottom: 3px;
                line-height: 26px;
            }
        }
        button {
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
            background-color: white;
            border: none;
            &:disabled {
                background-color: #e9e9e9;
            }
        }
    }
}
.table {
    display: table;
    text-indent: initial;
    border-spacing: 2px;
    border-collapse: collapse;
    margin: 0 !important;
    width: 100% !important;
    background-color: white;
    font-size: 1rem;
    .thead {
        display: table-header-group;
        vertical-align: middle;
        font-size: 12px;
        font-weight: 500;
        color: #004377;
        background: #7cc954;
    }
    .tr {
        display: table-row;
        vertical-align: inherit;
        select {
            border: 2px solid #7cc954;
            border-radius: 4px;
            color: #ff7049;
            outline: none;
        }
    }
    .th {
        vertical-align: bottom;
        padding: 1.1rem 1.5rem !important;
        white-space: nowrap;
        text-transform: uppercase;
        letter-spacing: 0.7px;
        text-align: inherit;
        text-align: -webkit-match-parent;
        display: table-cell;
    }
    .tbody {
        display: table-row-group;
        vertical-align: middle;
        border: none;
        font-weight: 500;
        font-size: 0.8em;
        color: #1d242e;
    }
    .td {
        vertical-align: middle !important;
        padding: 1.1rem 1.5rem !important;
        white-space: nowrap;
        display: table-cell;
        max-width: 500px;
        overflow-x: auto;
        border-bottom: 1px solid grey;
    }
    div ::-webkit-scrollbar {
        width: 5px !important;
        height: 5px !important;
    }
    div ::-webkit-scrollbar-thumb {
        background-color: #bbbbbb !important;
        border-radius: 3px !important;
    }
    div ::-webkit-scrollbar-track {
        border-radius: 3px !important;
        background-color: #e2dddd !important;
    }
}
.table-responsive {
    display: block;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: white;
    width: 100%;
    box-shadow: #1d242e;
    border: 1px solid grey;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
