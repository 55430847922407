// @import url('../asset/icons/Chevron.svg');

$primary: #333344;
$danger: #db303f;
$success: #146356;
$secondary: #d9c4a9;
$info: #197bbd;
$modal-content-border-radius: 16px;

@import '~bootstrap/scss/bootstrap';
@import './custom';
@import '../components/table/table';
